import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  constructor(private firestore: AngularFirestore) { }

  writeSubscriber(email){
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email != undefined && email != null && email.length != 0 && re.test(String(email).toLowerCase())) {
      // return this.http.post("http://firebase/", {"email":email});  
      return this.firestore.collection('subscribers').add({"email":email});
    }
    else {
      console.log("throwing error")
      throw new Error('error');
    }
   
  }
}
