import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from 'src/app/app.routes';
import { SubscribeService } from 'src/app/services/subscribe.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage} from '@angular/fire/storage'
/* Fix: in production build - TypeError: uf(...).firestore is not a function */
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { DemoComponent } from './demo/demo.component'; // import {firestore} from 'firebase/app'; does not import firestore code
/* Fix -- end */

/*
 * Amplify JS
*/
// import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
// import Auth from '@aws-amplify/auth';
// import Interactions from '@aws-amplify/interactions';
// import Storage from '@aws-amplify/storage';
import { FileUploadModule } from 'ng2-file-upload';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DemoComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    // AmplifyAngularModule
    FileUploadModule,
    FontAwesomeModule
  ],
  providers: [
    SubscribeService,
    AngularFirestore,
    AngularFireStorage,
    // {
    //   provide: AmplifyService,
    //   useFactory:  () => {
    //     return AmplifyModules({
    //       Auth,
    //       Storage,
    //       Interactions
    //     });
    //   }
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
