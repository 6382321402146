import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, Validator } from '@angular/forms';
import { SubscribeService } from '../services/subscribe.service'


@Component({
  selector: 'bv-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  emailCtrl: FormControl;

  subscribeForm: FormGroup;

  subscribed = false;
  showFeedbackMessage = false;
  feedbackMessage: string = "";

  constructor(private subscribeService: SubscribeService) {
    this.emailCtrl = new FormControl('',
      [
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
    this.subscribeForm = new FormGroup({
      emailCtrl: this.emailCtrl
    });
  }

  ngOnInit() {
  }

  storeSubscriber() {
    this.subscribeService.writeSubscriber(this.emailCtrl.value).then((data) => {
      this.subscribed = true;
      this.showFeedbackMessage = true;
      this.setFeedbackMessage();
    }, (error) => {

      this.subscribed = false;
      this.showFeedbackMessage = true;
      this.setFeedbackMessage();
    })
  }

  setFeedbackMessage() {
    if (this.subscribed) {
      this.feedbackMessage = "We're excited to connect with you! Please check your inbox for an acknowledgement and further follow-ups."
    } else {
      this.feedbackMessage = "Oops! Something went wrong, please try again."
    }
    this.subscribeForm.reset();
  }
}
