import { Component, OnInit } from '@angular/core';
// import { AmplifyService } from 'aws-amplify-angular';
import { UploadService } from '../services/upload.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { concat } from  'rxjs';


@Component({
  selector: 'bv-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  public uploader: FileUploader = new FileUploader({});
  public hasBaseDropZoneOver: boolean = false;

  fileOverBase(event):  void {
    this.hasBaseDropZoneOver  =  event;
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  upload() {   
    let files = this.getFiles();
    console.log(files);
    let requests = [];
    files.forEach((file) => {
      let formData = new FormData();
      formData.append('file' , file.rawFile, file.name);
      requests.push(this.uploadService.upload(formData));     
    });

    concat(...requests).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
  }
  // storageService;

  constructor(private uploadService:UploadService) {}
  // constructor( private amplifyService: AmplifyService ) {
    // this.amplifyService.auth();  
    //  this.storageService = this.amplifyService.storage();
  // }

  ngOnInit() {
  }

  // onImageLoaded(event) {
  //   console.log(event);
  // } 

  // onImagePicked(event) {
  //   console.log(event);
  // }
}