import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private httpClient: HttpClient) { }

  upload(formData) {
    let apiPath = "https://xcmb6tgske.execute-api.us-west-2.amazonaws.com/Prod/classify/";
    this.httpClient.post(apiPath, formData).subscribe((res)=>{
      console.log(res);
  });
  }
}
