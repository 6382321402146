import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DemoComponent } from './demo/demo.component';

export const appRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    // {
    //     path: 'demo',
    //     component: DemoComponent
    // },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'cpanel',
        redirectTo: '/cpanel',
    },
    {
        path: '**',
        redirectTo: '/home',
    }
];